import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { ServicesPage } from '../components/pages';

const Services: React.FC = () => {
  const pageData = useStaticQuery(graphql`
    query MyQuery {
      markdownRemark(frontmatter: { page: { eq: "services" } }) {
        frontmatter {
          primaryColor
          title
          subTitle
          text
          card1Title
          card1Text
          card2Title
          card2Text
          card3Title
          card3Text
          card4Title
          card4Text
        }
      }
    }
  `);
  const data = pageData.markdownRemark.frontmatter;
  return <ServicesPage data={data} />;
};

export default Services;
